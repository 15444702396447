import React, {} from 'react';
import dellLogo from "../images/white-dell@2x.png";
import nVidia from "../images/white-nvidia@2x.png";
import presidio from "../images/white-presidio@2x.png";
import BgImgMobiles from "../images/Mask-Group-3@2x.png";
import thumbnailOne from "../images/Group-1478@2x.png";
import thumbnailTwo from "../images/key-benefits@2x.png";
import thumbnailThree from "../images/blog-3.jpg";
import insightsData from '../data/insightsData.json'; // Import your JSON file
import {Fade} from "react-reveal";

const Hero = () => {
	const BackG   = `url(${BgImgMobiles})`;
	const BackOne   = `url(${thumbnailOne})`;
	const BackTwo   = `url(${thumbnailTwo})`;
	const BackThree   = `url(${thumbnailThree})`;
	return (
		<div className="relative top-0 bg-[#244739] bg-cover bg-center" style={{ backgroundImage: BackG }}>
			<div className="container relative top-0 bottom-0 left-0 right-0 flex flex-wrap items-center z-40 max-w-[1200px] w-[100%] lg:flex pt-[90px] pb-[75px] lg:pt-[180px] lg:pb-[160px] m-auto align-middle">
				<div className="relative w-[100%] px-[20px] lg:px-0 pt-0 lg:p-[0] lg:pr-[30px]">
					<Fade left delay={'500'}  >
					<div id="logos" className="pt-[20px] hidden lg:flex items-center justify-start mb-[40px] gap-[60px]">
					</div>
					</Fade>
					<div className="">
						<Fade bottom delay={'500'}><div className="text-[42px] font-light text-[#7BFC76] leading-[48px] mb-[20px]"><span className="font-bold">A</span>dvanced <span className="font-bold">I</span>nsight</div>
						</Fade>
						<Fade bottom delay={'500'}  >
						<p className="text-[16px] mt-[30px] leading-[22px] lg:text-[16px] lg:leading-[24px] text-[#ffffff] mb-4 max-w-[360px]">
							Read the latest from leading generative AI experts.
						</p>
						</Fade>
					</div>
				</div>
				<div className="container px-[20px] lg:px-0 relative z-40 max-w-[1300px] w-[100%] lg:flex lg:pb-[200px] pt-[69px] m-auto align-start">
					<div className="grid grid-cols-1 md:grid-cols-3 gap-[40px] md:gap-[10px] lg:gap-[40px] w-[100%]">
						{insightsData.items.map((item, index) => (
							<div key={index} className="col-span-1 bg-[#E4FFD6] drop-shadow-md">
								<figure className="bg-cover pb-[56%] w-[100%]" style={{ backgroundImage: `url(${item.thumbnail})` }}></figure>
								<div className="p-[10px] lg:p-[20px]">
									<div>
										<p className="text-[#6E6E6E] text-[9px] lg:text-[15px] mb-[10px]">{item.category}</p>
										<p className="text-[#247554] text-[24px] leading-[28px] lg:text-[30px] lg:leading-[36px] text-left">{item.title}</p>
									</div>
									<p className="mt-[30px]">
										<a href={item.link} className="inline-block px-[10px] py-[10px] bg-[#4DE760] text-[16px] text-[#000000] font-bold">Read More</a>
									</p>
								</div>
							</div>
						))}
					</div>
				</div>

			</div>
		</div>

	);
};

export default Hero;
