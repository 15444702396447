import React, {} from 'react';
import ThumbNail from "../images/ipaper_cover_cwb_v1.png";
import BgImgMobiles from "../images/impact.png";
import {Fade} from "react-reveal";


const Innovation = () => {
	const BgImage = `url(${ThumbNail})`; // Set none for mobile
	const backgroundImage = window.innerWidth <= 768 ? `none` : `url(${BgImgMobiles})`; // Set none for mobile
	return (
		<div className="parallax-container relative lg:px-[50px] pb-[50px] bg-cover bg-no-repeat bg-[#244739]" style={{ backgroundImage }}>
			<div className="container px-[20px] lg:px-0 relative z-40 max-w-[1200px] w-[100%] lg:flex pt-[90px] lg:pt-[160px] lg:pb-[160px]  m-auto align-start">
				<div className="lg:grid md:grid-cols-4 flex flex-col grid-cols-1 lg:grid-cols-4 gap-[60px] lg:gap-[150px] w-[100%]">
					<div className="col-span-2">
						<Fade bottom delay={'500'}  >
							<a href={'/#/guide'}>
								<figure className="bg-cover pb-[56%] w-[100%]" style={{ backgroundImage: BgImage }}>
								</figure>
							</a>
						</Fade>
					</div>
					<div className="col-span-2 text-left">
						<Fade bottom delay={'500'} >
						<h3 className="text-[#7BFC76] text-[30px] lg:text-[42px] mb-[10px] font-bold">
							A<span className="font-light">mplify the</span> I<span className="font-light">mpact</span>
						</h3>
						</Fade>
						<Fade bottom delay={'500'}  >
						<p className="text-[#ffffff] text-[16px] leading-[24px] max-w-[300px] text-left">
							Explore the complete guide to Dell Validated Design for Generative AI with NVIDIA to see how the joint
							solution can help you unlock the full potential of generative AI for your enterprise.
						</p>
						</Fade>
							<Fade bottom delay={'500'}  >
						<p className="mt-[30px]">
							<a href={'/#/guide'} className="px-[10px] py-[10px] bg-[#4DE760] text-[#000000] font-bold">
								See the Guide
							</a>
						</p>
							</Fade>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Innovation;