import React from 'react';
import { Fade } from 'react-reveal';
import insightsData from '../data/insightsData.json'; // Import your JSON file

const Insights = () => {
	return (
		<div className="parallax-container relative lg:px-[50px] bg-cover bg-no-repeat bg-[#244739] bg-top" style={{ backgroundImage: `url(${insightsData.BgImgMobiles})` }}>
			<div className="container px-[20px] lg:px-0 relative z-40 max-w-[1300px] w-[100%] lg:flex pt-[90px] lg:pt-[200px] lg:pb-[0px] m-auto align-start">
				<Fade bottom delay={500}>
					<div>
						<h3 className="text-[30px] lg:text-[42px] leading-[48px] text-[#7BFC76]"><span className={'font-bold'}>A</span>dvanced <span className={'font-bold'}>I</span>nsight</h3>
						<p className="text-[#ffffff] text-[16px] mt-[23px] mb-[50px]">Read the latest from leading generative AI experts.</p>
					</div>
				</Fade>
			</div>
			<div className="container px-[20px] lg:px-0 relative z-40 max-w-[1300px] w-[100%] lg:flex lg:pb-[200px] m-auto align-start">
				<Fade bottom delay={500}>
					<div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-[40px] w-[100%]">
						{insightsData.items.map((item, index) => (
							<div key={index} className="col-span-1 bg-[#E4FFD6] drop-shadow-md">
								<figure className="bg-cover pb-[56%] w-[100%]" style={{ backgroundImage: `url(${item.thumbnail})` }}></figure>
								<div className="p-[10px] lg:p-[20px]">
									<div>
										<p className="text-[#6E6E6E] text-[9px] lg:text-[15px] mb-[10px]">{item.category}</p>
										<p className="text-[#247554] text-[24px] leading-[28px] lg:text-[30px] lg:leading-[36px] text-left">{item.title}</p>
									</div>
									<p className="mt-[30px]">
										<a href={item.link} className="inline-block px-[10px] py-[10px] bg-[#4DE760] text-[16px] text-[#000000] font-bold">Read More</a>
									</p>
								</div>
							</div>
						))}
					</div>
				</Fade>
			</div>
		</div>
	);
};

export default Insights;
