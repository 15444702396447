import React, { useState, useEffect } from 'react';
import BgImgMobiles from "../images/hero-banner.png";

const StickyComponent = () => {
	const BackG = `url(${BgImgMobiles})`;

	return (
		<div className="relative top-0 bg-[#244739] py-32 bg-cover  lg:bg-cover bg-left lg:bg-top-[-100px] bg-no-repeat" style={{ backgroundImage: BackG }}>
			<div className="container px-[20px] lg:px-0 max-w-[1200px] mx-auto flex flex-wrap flex-col">
				<div className="GoAllInOnAi text-white text-base font-normal font-['Roboto'] uppercase leading-10">
					Go All In on AI
				</div>
				<div
					className="w-full max-w-[747px] text-white text-4xl font-normal font-['Roboto'] leading-9 mb-[30px]">Explore
					the power of Dell Validated Design for Generative AI with NVIDIA, powered by CWB
				</div>
				<div className="Link w-auto md:max-w-[300px] h-10 px-5 py-2 bg-red-500 justify-start items-start flex">
					<a href="/#/contact"
						className=" text-center text-white text-base font-bold font-['Roboto'] leading-normal">Claim
						FREE Gift and AI Assessment
					</a>
				</div>
			</div>
		</div>
	);
};

export default StickyComponent;
