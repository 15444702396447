// Save this SVG code in a separate file, e.g., ChevronDownIcon.js
import React from 'react';

const ChevronDownIcon = () => (
	<div className={'absolute -right-[20px] -top-[3px]'}>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className="h-4 w-4 inline"
			fill="none"
			viewBox="0 0 24 24"
			stroke="currentColor"
		>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M19 9l-7 7-7-7"
			/>
		</svg>
	</div>
);

export default ChevronDownIcon;
