import React, {} from 'react';
import ThumbNail from "../images/infographic_cwb_v1.png";
import BgImgMobiles from "../images/All-in-Accelerate-Innovation-3@2x.png";
import {Fade} from "react-reveal";

const Innovation = () => {
	const BgImage = `url(${ThumbNail})`; // Set none for mobile
	const backgroundImage = window.innerWidth <= 768 ? `none` : `url(${BgImgMobiles})`; // Set none for mobile
	return (
		<div className="parallax-container bg-center relative lg:px-[50px] xl:px-[0] bg-cover bg-no-repeat pb-[50px] lg:pb-0 bg-[#244739]" style={{ backgroundImage }}>
			<div className="container relative z-40 max-w-[1200px] w-[100%] lg:flex pt-[90px] lg:pt-[160px]  m-auto align-middle">
				<div className="lg:grid flex flex-col-reverse grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-[60px] lg:gap-[150px] w-[100%] px-[20px] lg:px-0">
					<div className="col-span-2">
						<Fade bottom delay={'500'}>
						<h3 className="text-[#7BFC76] text-[30px] lg:text-[42px] mb-[10px] font-bold">
							A<span className="font-light">ccelerate</span> I<span className="font-light">nnovation</span>
						</h3>
						</Fade>
						<Fade bottom delay={'500'}>
						<p className="text-[#ffffff] text-[16px] leading-[24px] max-w-[520px]">
							Dell Validated Design for Generative AI with NVIDIA is a full-stack solution built on Dell infrastructure and software, combined with NVIDIA’s award-winning software stack and accelerator technology.
							<br/><br/>
							Move quickly to deploy innovative generative AI applications that drive trusted decisions from your customized data to grow your businesses.
						</p>
						</Fade>

						<Fade bottom delay={'500'}  >
						<p className="mt-[30px]">

							<a href="#" target={'_blank'} className="px-[10px] py-[10px] bg-[#4DE760] text-[#000000] font-bold">
								See the Infographic
							</a>

						</p>
						</Fade>
					</div>
					<div className="col-span-2 text-center flex justify-center lg:justify-end">
						<Fade bottom delay={'500'}  >
						<figure className="bg-cover lg:pb-[600px] pb-[400px] w-[100%] max-w-[440px] bg-center" style={{ backgroundImage: BgImage }}>

						</figure>
						</Fade>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Innovation;