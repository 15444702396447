import React from "react";
import { motion } from "framer-motion";
import HeroContact from "../components/contact-hero.js";
import StickyComponent from "../components/stickyform";


const Blog3 = () => {
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.4 }}
			className="relative left-0"
		>
			<HeroContact />
		</motion.div>
	);
};

export default Blog3;
