import { HashRouter as Router } from "react-router-dom";
import AnimatedRoutes from "./templates/AnimatedRoutes";
import Navigation from "./components/navigation";
import Footer from "./components/footer";
import "./scss/app.scss";
import React from "react";
import 'react-reveal/globals';

function App() {
    return (
        <div className="App font-roboto">
            <Router>
                <Navigation />
                <AnimatedRoutes />
                <Footer />
            </Router>
        </div>
    );
}

export default App;
