import React, {} from 'react';
import BgImgMobiles from "../images/Mask-Group-3@2x.png";
import ThumbNail from "../images/blog-3.jpg"
import Support from "../images/guide-3-support@2x.png";
import {Fade} from "react-reveal";




const Hero = () => {
	const BackG   = `url(${BgImgMobiles})`;
	const ThumbNails = `url(${ThumbNail})`;
	return (
		<div className="relative top-0 pb-[100px]">

			<div className="relative top-0 bg-[#244739] bg-cover lg:bg-contain bg-left lg:bg-top-[-100px] bg-no-repeat" style={{ backgroundImage: BackG }}>
				<div className="container relative top-0 bottom-0 left-0 right-0 flex flex-wrap items-baseline z-40 max-w-[1200px] w-[100%] lg:flex pt-[90px] pb-[0px] lg: pt-[180px] lg:pb-[40px] m-auto content-start">
					<div className="relative w-[100%] lg:w-1/2 px-[20px] lg:px-0 pt-0 lg:p-[0] lg:pr-[30px]">
						<Fade left delay={'500'}  >
							<div id="logos" className="hidden pt-[20px] lg:flex items-center justify-start mb-[40px] gap-[60px]">
							</div>
						</Fade>
						<Fade bottom delay={'500'}>
							<div className="">
								<div className="text-[15px] text-[#ffffff] mb-[10px] lg:mb-0">GENERATIVE AI</div>
								<div className="lg:text-[42px] text-[32px] text-[#ffffff] max-w-[600px] leading-[48px] mb-[20px]">Exploring the use cases of Dell Validated Design for Generative AI with NVIDIA</div>
							</div>
						</Fade>
					</div>
					<div className="relative w-[100%] lg:w-1/2 lg:px-0 pt-0 lg:p-[0] lg:pr-[30px]">
						<figure className="pb-[56%] lg:absolute top-0 w-full mx-auto right-0 lg:w-[100%] bg-cover lg:max-w-[100%] left-0" style={{ backgroundImage : ThumbNails }}></figure>
					</div>
				</div>
			</div>

			<div className="max-w-[800px] mx-auto mt-[40px] lg:mt-[130px] px-[20px] lg:px-0">
				<Fade bottom delay={'500'}>
					<div className="font-semibold text-[#444444] mb-[20px]">
						Revolutionize your enterprise generative AI initiatives and create the unforgettable with the all-inclusive Dell Validated Design for Generative AI with NVIDIA. AI is set to be the most transformative technology in improving human experiences, enhancing business operations and improving cyber security.
						<br/><br/>
						Ensure your customers are ready to fully embrace this cutting-edge technology with best-in-class, full-stack solutions that optimize configurations and enable enterprises to deploy generative AI solutions across their business.
						<br/><br/>
						Together, Dell Technologies and CWB unlock automated ingenuity for businesses, but what solutions make up the offering and what are the key generative AI use cases that enterprises can take advantage of?
					</div>
					<div className="text-[#444444]">
						<p className="text-[#349E5F] text-[22px]">The solutions that make it possible</p><br/>
						Dell Validated Design for Generative AI with NVIDIA presents a complete blueprint for generative AI, streamlining right-sized configurations and expert advice for enterprises.
						<br/><br/>
						Leveraging Dell's infrastructure and expert advisors, businesses gain access to the PowerEdge XE9680 and PowerEdge R760xa, providing unmatched AI performance.
						<br/><br/>
						With resilient and scalable unstructured data storage, including Dell PowerScale and ECS Object Storage, and AI-enabled cloud-based monitoring via CloudIQ, businesses can easily manage and optimize their generative AI efforts.
						<br/><br/>
						Dell's award-winning ProSupport and ProDeploy services also ensure a seamless and successful implementation of generative AI projects for all enterprise customers.
						<br/><br/>
						NVIDIA's contribution to the joint solution is equally impressive. The integration of NVIDIA H100 Tensor Core GPUs into Dell PowerEdge platforms boosts AI capabilities, while NVIDIA's high-performance networking ensures seamless data flow.
						<br/><br/>
						The NVIDIA AI Enterprise software suite, equipped with the NeMo framework for generative AI development, empowers businesses to create advanced generative AI models, whilst NVIDIA Base Command Manager streamlines AI cluster deployment and management across edge, core, and cloud environments. This provides businesses with a comprehensive and efficient generative AI solution.
					</div>
				</Fade>
				<Fade bottom delay={'500'}  >
					<div className={'mb-[40px] mt-[40px]'}>
						<img src={Support} className={'h-auto w-full'}/>
					</div>
				</Fade>
				<Fade bottom delay={'500'}>
					<div className=" text-[#444444]">
						<p className="text-[#349E5F] text-[22px]">Bringing generative AI to life</p><br/>
						Generative AI introduces unprecedented opportunities for businesses to elevate their operations and drive richer connected experiences and lasting customer impacts from their customized data.
						<br/><br/>
						<p className="text-[#349E5F] text-[22px]">Customer service</p><br/>
						Improve chatbot intent detection, summarize conversations, respond to customer questions and direct customers to relevant resources. Elevate the customer experience by combining the power of Dell Technologies with CWB and feel the impact.
						<br/><br/>
						<p className="text-[#349E5F] text-[22px]">Content creation</p><br/>
						Make product descriptions, social media posts, news articles, and even books. By automating the content creation process, businesses can save large amounts of time and money.
						<br/><br/>
						<p className="text-[#349E5F] text-[22px]">Sales and marketing</p><br/>
						Create personalized customer experiences, such as customized product recommendations or personalized marketing messages.
						<br/><br/>
						<p className="text-[#349E5F] text-[22px]">Product design</p><br/>
						Create new products or improve existing ones. A generative AI model can be trained on images of existing products to generate new designs that meet the specific needs of your customers.
						<br/><br/>
						<p className="text-[#349E5F] text-[22px]">Education</p><br/>
						Create personalized learning experiences, learning plans, and custom learning materials.
						<br/><br/>
						<p className="text-[#349E5F] text-[22px]">Fraud detection</p><br/>
						Detect and avoid fraud in financial transactions and other situations. Generative AI models can be trained to detect fraudulent patterns and flag suspicious transactions.
						<br/><br/>
						<p className="text-[#349E5F] text-[22px]">Software development</p><br/>
						Write code in human language, convert code from one programming language to another, fix broken code, or explain code.
						<br/><br/>
						<p className="text-[#349E5F] text-[22px]">Unlocking the future today</p><br/>
						Dell Validated Design for Generative AI with NVIDIA is the ultimate answer for businesses seeking to unlock the true potential of their data with generative AI, enabling businesses to streamline complex processes, increase productivity and accelerate results and outcomes.
						<br/><br/>
						CWB is leveraging the transformative power of AI to unleash the power of connected customer experiences to create the unforgettable for customers and businesses. The advanced technology and solutions of CWB enable them to build the experience, secure what matters and connect like never before.
						<br/><br/>
						As generative AI continues to transform industries, Dell Validated Design for Generative AI with NVIDIA and CWB stand as the catalyst that drives businesses towards a future of boundless possibilities and next-level experiences.
					</div>
				</Fade>

			</div>

		</div>

	);
};

export default Hero;
