import React from "react";
import { motion } from "framer-motion";
import Hero from "../components/hero.js";
import Overview from "../components/overview.js";
import Innovation from "../components/innovation.js";
import Impact from "../components/impact.js";
import Insights from "../components/insights.js";
import Inspiring from "../components/inspiring.js";
import Information from "../components/information.js";
import StickyComponent from "../components/stickyform";

const Home = () => {
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.4 }}
			className="relative left-0"
		>
			<Hero />
			<Overview />
			<Innovation />
			<Impact />
			<Insights />
			<Inspiring />
			<Information />
			<StickyComponent />
		</motion.div>
	);
};

export default Home;
