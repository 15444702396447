import React, {} from 'react';
import { Fade } from 'react-reveal';
import BgImgMobiles from "../images/Mask-Group-3@2x.png";
import ThumbNail from "../images/key-benefits@2x.png"
import Support from "../images/guide-support-2@2x.png";


const Hero = () => {
	const BackG   = `url(${BgImgMobiles})`;
	const ThumbNails = `url(${ThumbNail})`;
	return (
		<div className="relative top-0 pb-[100px]">
			<div className="relative top-0 bg-[#244739] bg-cover lg:bg-contain bg-left lg:bg-top-[-100px] bg-no-repeat" style={{ backgroundImage: BackG }}>
				<div className="container relative top-0 bottom-0 left-0 right-0 flex flex-wrap items-baseline z-40 max-w-[1200px] w-[100%] lg:flex pt-[90px] pb-[0px] lg: pt-[180px] lg:pb-[40px] m-auto content-start">
					<div className="relative w-[100%] lg:w-1/2 px-[20px] lg:px-0 pt-0 lg:p-[0] lg:pr-[30px]">
						<Fade left delay={'500'}  >
						<div id="logos" className="hidden pt-[20px] lg:flex items-center justify-start mb-[40px] gap-[60px]">

						</div>
						</Fade>
						<Fade bottom delay={'500'}>
						<div className="">
							<div className="text-[15px] text-[#ffffff] mb-[10px] lg:mb-0">GENERATIVE AI</div>
							<div className="lg:text-[42px] text-[32px] text-[#ffffff] max-w-[600px] leading-[48px] mb-[20px]">Realize the four key benefits of Dell Validated Design for Generative AI with NVIDIA</div>
						</div>
						</Fade>
					</div>
					<div className="relative w-[100%] lg:w-1/2 lg:px-0 pt-0 lg:p-[0] lg:pr-[30px]">
						<figure className="pb-[56%] lg:absolute top-0 w-full mx-auto right-0 lg:w-[100%] bg-cover lg:max-w-[100%] left-0" style={{ backgroundImage : ThumbNails }}></figure>
					</div>
				</div>
			</div>
			<div className="max-w-[800px] px-[20px] lg:px-0 mx-auto mt-[40px] lg:mt-[130px]">
				<Fade bottom delay={'500'}  >
				<div className="font-semibold text-[#444444] mb-[20px]">
					Generative AI is revolutionizing the way enterprises operate, opening up new possibilities for organizations like CWB to drive innovation across their industry and create the unforgettable.
				</div>
				<div className="text-[#444444]">
					As businesses strive to stay ahead in today's rapidly evolving technology landscape, embracing generative AI offers critical benefits that can elevate their operations to new heights.
				</div>
				</Fade>
				<Fade bottom delay={'500'}  >
				<div className={'mb-[40px] mt-[40px]'}>
					<img src={Support} className={'h-auto w-full'}/>
				</div>
				</Fade>
				<Fade bottom delay={'500'}  >
				<div className=" text-[#444444]">
					<p className="text-[#349E5F] text-[22px] mb-[10px]">Boost productivity</p>
					With Dell Validated Design for Generative AI with NVIDIA, businesses can streamline the entire generative AI lifecycle, from infrastructure provisioning to application development and deployment. It is now simpler than ever to build on-premises generative AI infrastructure at scale with an AI-accelerated foundation and validated designs. Empower teams to deliver data-driven applications, models and operations that automate tasks, drive faster insights and fuel more cost-effective decision-making.
					<br/><br/>
					<p className="text-[#349E5F] text-[22px] mb-[10px]">Enhance flexibility</p>
					Power a wide range of use cases and computational requirements on the modular, future-proofed foundation of Dell Validated Design for Generative AI with NVIDIA. Organizations can build their infrastructure with a focus on specific aspects of generative AI workloads, with the flexibility to expand as needed. The modular software stack also enables AI researchers, data scientists and engineers to remain agile when rapidly designing and deploying new infrastructure that decreases time-to-value.
					<br/><br/>
					<p className="text-[#349E5F] text-[22px] mb-[10px]">Embrace scalability</p>
					Built to be scalable and easily accommodate changing business needs, organizations can embrace agility with Dell Validated Design for Generative AI with NVIDIA. The commitment of Dell and NVIDIA to scalability, modularity and high performance supports the seamless integration of generative AI across all enterprise domains.
					<br/><br/>
					<p className="text-[#349E5F] text-[22px] mb-[10px]">Prioritize security</p>
					Security is the cornerstone of transformative business. Dell Validated Design for Generative AI with NVIDIA includes built-in security features that are seamlessly integrated with continuously improved security controls and solutions to keep up with evolving threats. The extensive feature set, which leverages intelligence and automation, not only provides robust protection but also adapts to expanding usage models, ensuring enterprises stay one step ahead of potential threats.
					<br/><br/>
					Generative AI is the most transformative technology, with the potential to improve the human experience, enhance business operations, improve cyber security and create the unforgettable.
					<br/><br/>
					CWB recognizes how embracing generative AI's key benefits can achieve gain a competitive edge and unlock new realms of productivity, flexibility, creativity, scalability and security. As we move into a more technology-driven future, leveraging the transformative power of generative AI is essential for businesses to stay agile, relevant and resilient in an ever-changing landscape.
				</div>
				</Fade>
			</div>
		</div>

	);
};

export default Hero;
