import React from "react";
import {
	Routes, // instead of "Switch"
	Route,
	useLocation,
} from "react-router-dom";
import Home from "./Home";
import Guide from "./Guide1";
import Webinar from "./webinar";
import Insights from "./Insights";
import Information from "./Information";
import HeroGuide3 from "./blog3";
import HeroGuide2 from "./blog2"
import HeroGuide1 from "./blog1"
import Contact    from "./contact";
import { AnimatePresence } from "framer-motion";

function AnimatedRoutes() {
	const location = useLocation();
	return (
		<main id="main" className="animatedRoutes">
			<AnimatePresence mode="wait" initial={false}>
				<Routes location={location} key={location.pathname}>
					<Route path="/" element={<Home />} />
					<Route path="/guide" element={<Guide />} />
					<Route path="/webinars" element={<Webinar />} />
					<Route path="/insights" element={<Insights />} />
					<Route path="/information" element={<Information />} />
					<Route path="/guide-3" element={<HeroGuide3 />} />
					<Route path="/guide-2" element={<HeroGuide2 />} />
					<Route path="/guide-1" element={<HeroGuide1 />} />
					<Route path="/contact" element={<Contact />} />
				</Routes>
			</AnimatePresence>
		</main>

	);
}

export default AnimatedRoutes;
