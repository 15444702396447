import React from "react";
import { motion } from "framer-motion";
import HeroInsights from "../components/hero-insights.js";
import StickyComponent from "../components/stickyform";


const Insights = () => {
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.4 }}
			className="relative left-0"
		>
			<HeroInsights />
			<StickyComponent />
		</motion.div>
	);
};

export default Insights;
