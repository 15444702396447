import React, {} from 'react';
import BgImgMobiles from "../images/All-in-Header-3.jpg";
import BgImgDesktop from "../images/guide-hero@2x.png";
import GuideHero from "../images/ipaper_cover_cwb_v1.png";
import mobileImg from "../images/guide-mobile-hero@2x.png";
import {Fade} from "react-reveal";

const Hero = () => {
	const backgroundImage = window.innerWidth <= 1025 ? `url(${BgImgMobiles})` : `url(${BgImgDesktop})`; // Set none for mobile
	return (
		<div className="relative top-0 relative bg-cover lg:bg-contain bg-top lg:bg-[top_100] bg-no-repeat w-[100%] z-30" style={{backgroundImage, backgroundPosition: '0px -100px'}}>
			<div className="container relative top-0 bottom-0 left-0 right-0 flex flex-wrap items-center z-40 max-w-[1200px] w-[100%] lg:flex pt-[90px] pb-[75px] md:pt-[150px] lg:pt-[180px] lg:pb-[160px] m-auto align-middle">
				<div className="relative w-[100%] pt-0 lg:p-[0] lg:pr-[30px]">
					<Fade left delay={'500'}  >
					<div id="logos" className="lg:flex pt-[20px] hidden items-center justify-start mb-[40px] gap-[60px]">

					</div>
					</Fade>

					<div className="px-[20px] lg:px-0">
						<Fade bottom delay={'500'}>
							<div className="lg:text-[42px] text-[42px] text-[#349E5F] font-light leading-[48px] mb-[20px]"><span className={'font-bold'}>A</span>mplify the <span className={'font-bold'}>I</span>mpact</div>
						</Fade>
						<Fade bottom delay={'500'}  >
						<p className="text-[15px] mt-[30px] font-light leading-[22px] lg:text-[16px] lg:leading-[24px] text-[#4444444] mb-4 max-w-[360px]">
							Discover how Dell Technologies and NVIDIA can help you seize the generative AI opportunity.
						</p>
						</Fade>
						<Fade bottom delay={'500'}  >
						<img src={mobileImg} className="lg:hidden block" />
						</Fade>
					</div>
					<div className="px-[20px] lg:px-0 lg:flex items-end w-[100%] gap-[20px] text-[15px] mt-[30px] leading-[22px] lg:text-[16px] lg:leading-[24px] text-[#4444444] mb-4 ">
						<div className="max-w-[100%] lg:max-w-[50%]">
							<Fade bottom delay={'500'}  >The guide covers:</Fade>
							<Fade bottom delay={'500'}  >
							<ul className="list-disc pl-[15px] md:pl-[20px] mt-[20px] space-y-3">
								<li>The growing generative AI opportunity</li>
								<li>AI challenges facing enterprises</li>
								<li>Addressing enterprise needs</li>
							</ul>
							</Fade>
						</div>
						<div className="lg:max-w-[50%]">
							<Fade bottom delay={'500'}  >
							<ul className="list-disc pl-[15px] md:pl-[20px] mt-[15px] lg:mt-[20px] space-y-3">
								<li>The solutions and benefits</li>
								<li>Key verticals and use cases</li>
								<li>The Dell Technologies and NVIDIA partnership</li>
							</ul>
							</Fade>
						</div>
					</div>

				</div>
				<div className="video mt-[100px] px-[20px]">
					<a href={''} target={'_blank'}><img src={GuideHero} className={'w-full h-auto'} /></a>
				</div>
			</div>
		</div>
	);
};

export default Hero;
