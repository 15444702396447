import React, { useState, useEffect } from 'react';
import dellLogo from "../images/DT_PartnerProgram_Black@2x.png";
import nVidia from "../images/nvidia@2x.png";
import presidio from "../images/logo_cwb.png";
import BgImgMobiles from "../images/All-in-Header-1_BG@2x.png";
import BgImgDesktop from "../images/All-in-Header-1.jpg";
import VideoSource from "../images/101337_Dell_NVIDIA_Gen_AI_V8_CL.mp4";
import clickToPlayImg from "../images/101337_Dell-NVIDIA_Campaign_Thumbnail1.png";
import mobileImg from "../images/home-letterbox@2x.png";
import { Fade } from "react-reveal";

const Hero = () => {
	const [backgroundImage, setBackgroundImage] = useState(
		window.innerWidth <= 1025 ? `url(${BgImgMobiles})` : `url(${BgImgDesktop})`
	);

	const [videoVisible, setVideoVisible] = useState(false);

	const showVideo = () => {
		setVideoVisible(!videoVisible);
	};

	// Update background image when window is resized
	useEffect(() => {
		const handleResize = () => {
			const newBackgroundImage =
				window.innerWidth <= 1025 ? `url(${BgImgMobiles})` : `url(${BgImgDesktop})`;
			setBackgroundImage(newBackgroundImage);
		};

		// Attach the event listener when the component mounts
		window.addEventListener('resize', handleResize);

		// Detach the event listener when the component unmounts
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []); // Empty dependency array ensures that this effect runs only once

	return (
		<div className="relative top-0 relative bg-cover md:bg-cover lg:bg-contain bg-top lg:bg-top-[100px] bg-no-repeat w-[100%] z-30" style={{backgroundImage, backgroundPosition: '0px -100px'}}>
			<div className="container relative top-0 bottom-0 left-0 right-0 flex flex-wrap items-center z-40 max-w-[1200px] w-[100%] lg:flex pt-[90px] pb-[75px] md:pt-[150px] lg:pt-[180px] lg:pb-[160px] m-auto align-middle">
				<div className="w-[100%] px-[20px] lg:px-0 pt-0 lg:pb-[30px] lg:pr-[30px] lg:w-1/2">
					<div id="logos" className="hidden lg:flex pt-[20px] items-center justify-start mb-[60px] gap-[60px]">
						<Fade left delay={'500'}  >

						</Fade>
					</div>
					<div className="">
						<Fade bottom delay={'500'}>
						<div className="md:text-[60px] text-[42px] text-[#349E5F] leading-[66px] mb-[20px]"><span className="font-bold">A</span>ll <span className="font-bold">I</span>n on AI</div>
						</Fade>
						<Fade bottom delay={'500'}  >
						<p className="text-[15px] mt-[30px] leading-[22px] lg:text-[24px] lg:leading-[30px] text-[#4444444] mb-4 md:max-w-[300px] lg:max-w-[450px] md:mb-[100px]">
							Unleash the power of your data with Dell Validated Design for Generative AI with NVIDIA.
						</p>
						</Fade>
						<Fade bottom delay={'500'}  >
						<img src={mobileImg} className="lg:hidden block w-full" alt="Mobile Image" onClick={showVideo} style={{ cursor: 'pointer' }} />
						</Fade>
					</div>
				</div>

				{!videoVisible && ( // Display the "click to play" image when the video is not visible
					<img src={clickToPlayImg} alt="Click to Play" onClick={showVideo} style={{ cursor: 'pointer' }} class={'w-[100%] h-auto mt-[60px]'}/>
				)}
				<div className={`video mt-[60px] px-[20px] ${videoVisible ? 'block' : 'hidden'}`}>
					{videoVisible && ( // Display the video when it's visible
						<video className="w-full" controls autoPlay>
							<source src={VideoSource} type="video/mp4" />
							Your browser does not support the video tag.
						</video>
					)}
				</div>
			</div>
		</div>
	);
};

export default Hero;
