import React, {useEffect, useState} from 'react';
import dellLogo from "../images/DT_PartnerProgram_Black@2x.png";
import nVidia from "../images/nvidia@2x.png";
import presidio from "../images/logo_cwb.png";
import BgImgMobiles from "../images/All-in-Header-2.jpg";
import BgImgDesktop from "../images/hero-wehinar@2x.png";
import clickToPlayImg from "../images/101337_Dell-NVIDIA_Interactive_Thumbnail1.png";
import mobileImg from "../images/webinar-mobile-hero@2x.png";
import {Fade} from "react-reveal";

const Hero = () => {
	const [backgroundImage, setBackgroundImage] = useState(
		window.innerWidth <= 1025 ? `url(${BgImgMobiles})` : `url(${BgImgDesktop})`
	);

	const [videoVisible, setVideoVisible] = useState(false);

	const showVideo = () => {
		setVideoVisible(!videoVisible);
	};
	useEffect(() => {
		const handleResize = () => {
			const newBackgroundImage =
				window.innerWidth <= 1025 ? `url(${BgImgMobiles})` : `url(${BgImgDesktop})`;
			setBackgroundImage(newBackgroundImage);
		};

		// Attach the event listener when the component mounts
		window.addEventListener('resize', handleResize);

		// Detach the event listener when the component unmounts
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []); // Empty dependency array ensures that this effect runs only once

	const videoUrl = 'https://player.vimeo.com/video/878317541?h=bb017f163f&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479';
	return (
		<div className="relative top-0 relative bg-cover md:bg-cover lg:bg-contain bg-top lg:bg-top-[100px] bg-no-repeat w-[100%] z-30" style={{backgroundImage, backgroundPosition: '0px -100px'}}>
			<div className="container relative top-0 bottom-0 left-0 right-0 flex flex-wrap items-center z-40 max-w-[1200px] w-[100%] lg:flex pt-[90px] pb-[75px] md:pt-[150px] lg:pt-[180px] lg:pb-[160px] m-auto align-middle">
				<div className="relative w-[100%] px-[20px] lg:px-0 pt-0 lg:p-[0]">

					<Fade left delay={'500'}  >
					<div id="logos" className="lg:flex hidden pt-[20px] flex items-center justify-start mb-[40px] gap-[60px]">

					</div>
					</Fade>
					<div className="">
						<Fade bottom delay={'500'}>
						<div className="lg:text-[42px] text-[42px] text-[#349E5F] leading-[48px] mb-[20px] font-semibold ">A<span className={'font-light'}>lways</span> I<span className={'font-light'}>nspiring</span></div>
						</Fade>
						<Fade bottom delay={'500'}  >
						<p className="text-[15px] mt-[30px] leading-[22px] lg:text-[16px] lg:leading-[24px] text-[#4444444] mb-4 max-w-[360px]">
							Tune in to our interactive webinar to hear more about the growing generative AI opportunity
							and how Dell Validated Design for Generative AI with NVIDIA places enterprises in prime position for business growth.
							<br/><br/>

						</p>
						<img alt={'image'} src={mobileImg} className="lg:hidden block" />
						</Fade>
					</div>
					<Fade left delay={'500'}>
						<div>
							<div className="lg:text-[32px] text-[42px] text-[#349E5F] font-light leading-[40px] mb-[20px] text-center mb-[40px] mt-[60px]">Start your interactive journey through AI by clicking below.
							</div>
						</div>
					</Fade>

				</div>

				<img src={clickToPlayImg} alt="Click to Play" className={'w-[100%] h-auto mt-[0px]'} id={'webinar'}/>

				{/*
				{!videoVisible && ( // Display the "click to play" image when the video is not visible
					<img src={clickToPlayImg} alt="Click to Play" onClick={showVideo} style={{ cursor: 'pointer' }} className={'w-[100%] h-auto mt-[0px]'} id={'webinar'}/>
				)}
				<div className={`video w-full mt-[60px] px-[20px] ${videoVisible ? 'block' : 'hidden'}`}>
					{videoVisible && ( // Display the video when it's visible
						<div className={'pt-[56%] relative'} style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
							<iframe className="hidden"
								src={videoUrl}
								frameBorder="0"
								allow="autoplay; fullscreen; picture-in-picture"
								style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'}}
								title="Dell Generative AI with NVIDIA for Enterprises - Interactive Webinar"
							/>
						</div>
					)}
				</div>
				*/}
			</div>

		</div>
	);
};

export default Hero;
