import React from 'react';
import dellLogo from "../images/DT_PartnerProgram_Black@2x.png";
import nVidia from "../images/nvidia@2x.png";
import presidio from "../images/logo_cwb.png";



const Navigation = () => {
	return (
		<footer className="bg-[#031859] p-4 flex justify-between items-center py-[28px]">

				<div className="container max-w-[1200px] mx-auto w-[100%]">
					<div className={'flex flex-wrap lg:flex-row flex-col justify-end items-center content-center'}>
						<div className="w-full text-[#ffffff] text-[10px] lg:text-[16px] lg:text-center text-center mb-[20px] lg:mb-0">
							Copyright © 2024 Dell Inc.
						</div>
					</div>
				</div>

		</footer>
	);
};

export default Navigation;