import React, { useEffect, useState, useRef } from 'react';
import BgImgMobiles from "../images/hero-banner.png";
import ThumbNail from "../images/gift-image-alexa.png";
import Icon1 from "../images/icon1.png";
import Icon2 from "../images/icon2.png";
import Icon3 from "../images/icon3.png";
import Icon4 from "../images/icon4.png";
import { Fade } from "react-reveal";

const Hero = () => {
	const BackG = `url(${BgImgMobiles})`;
	const ThumbNails = `url(${ThumbNail})`;
	const Icons1 = `url(${Icon1})`;
	const Icons2 = `url(${Icon2})`;
	const Icons3 = `url(${Icon3})`;
	const Icons4 = `url(${Icon4})`;

	const formRef = useRef(null);
	const fixedButtonRef = useRef(null);
	const [isSticky, setIsSticky] = useState(false);
	const [isButtonVisible, setIsButtonVisible] = useState(true);

	useEffect(() => {
		const handleScroll = () => {
			if (formRef.current && fixedButtonRef.current) {
				const formTop = formRef.current.getBoundingClientRect().top;
				const fixedButtonTop = fixedButtonRef.current.getBoundingClientRect().top;
				setIsButtonVisible(fixedButtonTop < formTop);

				const navbar = document.querySelector('.navbarNav');
				const supportingImage = document.querySelector('.supporting-image');

				if (navbar && supportingImage) {
					const navbarOffset = navbar.getBoundingClientRect().bottom;
					const supportingImageOffset = supportingImage.getBoundingClientRect().top;

					setIsSticky(navbarOffset >= supportingImageOffset);
				}
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const [hideDiv, setHideDiv] = useState(false);

	useEffect(() => {
		const div1 = document.getElementById('div1');
		const div2 = document.getElementById('div2');

		const handleScroll = () => {
			const div2Rect = div2.getBoundingClientRect();
			const windowHeight = window.innerHeight;

			if (div2Rect.top <= windowHeight) {
				setHideDiv(true);
			} else {
				setHideDiv(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		// Clean up the event listener when the component unmounts
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);


	const scrollToRef = (ref) => {
		window.scrollTo({
			top: ref.current.offsetTop,
			behavior: 'smooth'
		});
	};

	return (
		<div className="relative top-0 pb-[50px]">
			<div className="relative top-0 bg-[#244739] bg-cover lg:bg-cover bg-left lg:bg-top-[-100px] bg-no-repeat" style={{ backgroundImage: BackG }}>
				<div className="relative top-0 bottom-0 left-0 right-0 flex flex-wrap items-baseline z-40 w-[100%] lg:flex pb-[0px] lg:pb-[40px] m-auto content-start">
					<div className="container relative top-0 bottom-0 left-0 right-0 flex flex-wrap items-baseline z-40 max-w-[1200px] w-[100%] lg:flex pt-[90px] pb-[0px] lg:pt-[180px] lg:pb-[250px] m-auto content-start">
						<div className="relative w-[100%] lg:w-2/3 px-[20px] lg:px-0 pt-0 lg:p-[0] lg:pr-[30px]">
							<Fade bottom delay={'500'}>
								<div className="relative">
									<div className="text-[15px] text-[#ffffff] mb-[10px] lg:mb-[10px]">Go All In on AI</div>
									<div className="lg:text-[42px] text-[32px] text-[#ffffff] max-w-[600px] leading-[48px] mb-[20px]">Explore the power of Dell Validated Design for Generative AI with NVIDIA to:</div>

									<div className="Rectangle1942 w-full p-2 bg-white bg-opacity-95 rounded-3xl mb-[30px]">
										<div className="text-black text-lg font-normal font-['Roboto'] leading-none">
											Advance innovation, Analyze information, Access insights, Awaken ideas |
										</div>
									</div>
								</div>
							</Fade>
						</div>
					</div>
				</div>
			</div>
			<div ref={fixedButtonRef}>
				{!hideDiv && (
					<div id={'div1'} className="fixed z-50 bottom-4 md:hidden Link w-auto max-w-[300px] h-10 px-5 py-2 bg-red-500 justify-start items-start flex left-0 right-0 mx-auto">
						<a onClick={() => scrollToRef(formRef)}
						   className=" text-center text-white text-base font-bold font-['Roboto'] leading-normal">Claim
							FREE Gift and AI Assessment
						</a>
					</div>
				)}
			</div>
			<div className="max-w-[1200px] flex flex-col lg:flex-row mx-auto mt-[40px] lg:-mt-[180px] px-[20px] lg:px-0 relative">

				<div className="w-full lg:w-2/3 font-semibold text-[#444444] mb-[20px] lg:pr-[60px]">
					<div className="relative w-[100%] pb-[40px] supporting-image">
						<figure className="pb-[100%] lg:pb-[56%] top-0 w-full mx-auto right-0 lg:w-[100%] lg:max-w-[100%] left-0 bg-no-repeat bg-contain bg-center bg-[#f7f7f7]" style={{ backgroundImage: ThumbNails }}></figure>
					</div>
					<div
						className="w-full text-red-500 text-3xl font-bold leading-10 mb-[20px]">
						Get Your FREE smart speaker and AI Assessment*
					</div>
					<div
						className="w-full">
						<div className="text-black text-base font-bold leading-normal mb-[20px]">CWB is the leader in leveraging data analytics to modernize, standardize and humanize the global cotton wool ball industry for over two decades. We’re All In on AI, and we want you to see some of the many ways we can help transform your business.<br/></div>
						<div className="text-black text-base font-normal leading-normal">Even if you already have an advanced analytics practice, your data still has more to say. Get your data’s full story with Dell Validated Design for Generative AI with NVIDIA, powered by CWB. Inc. Uncover insights about major aspects of your organization, including areas such as:</div>
					</div>

					<div className="flex gap-[60px] flex-col md:flex-row align-start mt-[60px]">
						<div className="w-full flex flex-wrap md:w-1/2 text-center">
							<div className="Icons1 w-full h-14 relative mb-[20px]"><figure style={{ backgroundImage: Icons1 }} className="w-full pb-14 bg-contain bg-center bg-no-repeat"></figure></div>
							<span className="text-black w-full text-base font-bold font-['Roboto'] leading-normal">Elevate customer experiences</span>
							<span className="text-black w-full text-base font-normal font-['Roboto'] leading-normal">by automating chatbot intent identification, summarizing conversations and directing customers to the right resources.</span>
						</div>

						<div className="w-full flex flex-wrap md:w-1/2 text-center">
							<div className="Icons1 w-full h-14 relative mb-[20px]"><figure style={{ backgroundImage: Icons2 }} className="w-full pb-14 bg-contain bg-center bg-no-repeat"></figure></div>
							<span className="text-black w-full text-base font-bold font-['Roboto'] leading-normal">Enhance security</span>
							<span className="text-black w-full text-base font-normal font-['Roboto'] leading-normal">and protect against fraudulent behavior by training generative AI models to recognize patterns and flag suspicious transactions.</span>
						</div>
					</div>

					<div className="flex gap-[60px] flex-col md:flex-row align-start mt-[30px] mb-[60px]">
						<div className="w-full flex flex-wrap md:w-1/2 text-center">
							<div className="Icons1 w-full h-14 relative mb-[20px]"><figure style={{ backgroundImage: Icons3 }} className="w-full pb-14 bg-contain bg-center bg-no-repeat"></figure></div>
							<span className="text-black w-full text-base font-bold font-['Roboto'] leading-normal">Automate coding tasks</span>
							<span className="text-black text-base w-full font-normal font-['Roboto'] leading-normal">convert code between programming languages, correct errors and explain code for seamless development.</span>
						</div>

						<div className="w-full flex flex-wrap md:w-1/2 text-center">
							<div className="Icons1 w-full h-14 relative mb-[20px]"><figure style={{ backgroundImage: Icons4 }} className="w-full pb-14 bg-contain bg-center bg-no-repeat"></figure></div>
							<span className="text-black w-full text-base font-bold font-['Roboto'] leading-normal">Deliver personalized sales and marketing experiences</span>
							<span className="text-black w-full text-base font-normal font-['Roboto'] leading-normal">to customers with customized product recommendations and personalized marketing messages.</span>
						</div>
					</div>
					<div className="Rectangle1941 w-full p-[30px] text-center bg-neutral-100">
						<div
							className="WhatHappensNextCompleteAndSubmitTheFormToScheduleACallYourFreeGiftWillBeDeliveredAfterTheCallTakesPlaceThisFreeGiftOfferOfAnAmazonEchoDotWithClock5thGenIsOnlyForALimitedTime w-full text-center">
							<span className="text-black text-base font-bold font-['Roboto'] leading-normal">What happens next...<br/><br/></span>
							<span className="text-black text-base font-normal font-['Roboto'] leading-normal">Complete and submit the form to schedule a call.<br/>Your FREE Gift will be delivered after the call takes place.<br/><br/></span>
							<span className="text-black text-base font-normal font-['Roboto'] leading-normal">*This FREE gift offer of an Amazon Echo Dot With Clock (5th Gen) is only for a limited time</span>
						</div>
					</div>
				</div>

				<div className={`w-[100%] lg:w-1/3`}>
					<div ref={formRef} id={'div2'} className={`sticky-container  ${isSticky ? 'lg:fixed lg:top-[20%]' : ''}`}>
						<div className="Rectangle1933 w-full lg:w-96 lg:max-w-96 bg-[#031A89] p-[20px]">
							<div className="w-full mx-auto text-center text-slate-50 text-base font-bold font-['Roboto'] leading-normal">
								Complete this form NOW to claim your gift and book your FREE AI Assessment
							</div>
							<div>
								<form action="" className="lg:w-full py-[20px] mx-auto rounded">
									<div className="shadow flex gap-[20px]">
										<div
											className="w-1/2 items-center mb-2">
											<label htmlFor="twitter"
											       className="w-full text-left pb-2 text-white">First name</label>
											<div className="Rectangle1935 w-full h-10 bg-white border border-sky-500"></div>
										</div>
										<div
											className="w-1/2 items-center mb-2">
											<label htmlFor="twitter"
											       className="w-full text-left pb-2 text-white">Last name</label>
											<div className="Rectangle1935 w-full h-10 bg-white border border-sky-500"></div>
										</div>
									</div>
									<div>
										<div
											className="w-full items-center mb-2">
											<label htmlFor="twitter"
											       className="w-full text-left pb-2 text-white">Business email</label>
											<div className="Rectangle1935 w-full h-10 bg-white border border-sky-500"></div>
										</div>
									</div>
									<div className="shadow flex gap-[20px] mb-10">
										<div
											className="w-1/2 items-center mb-2">
											<label htmlFor="twitter"
											       className="w-full text-left pb-2 text-white">Company name</label>
											<div className="Rectangle1935 w-full h-10 bg-white border border-sky-500"></div>
										</div>
										<div
											className="w-1/2 items-center mb-2">
											<label htmlFor="twitter"
											       className="w-full text-left pb-2 text-white">Phone number</label>
											<div className="Rectangle1935 w-full h-10 bg-white border border-sky-500"></div>
										</div>
									</div>
									<button
										className="bg-[#F5442D] block w-full rounded py-2 text-white font-bold shadow">Claim FREE Gift and AI Assessment
									</button>

								</form>
							</div>
							<div className="w-full">
								<div className="text-slate-50 text-xs font-normal font-['Roboto'] leading-none">Dell Technologies needs the contact information you provide to us to contact you about our products and services. You may unsubscribe from these communications at any time. For information on how to unsubscribe, as well as our privacy practices and commitment to protecting your privacy, please review our
									<span className="text-slate-50 text-xs font-normal font-['Roboto'] underline leading-none">Privacy Policy</span>.</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Hero;
