import React, {} from 'react';
import BgImgMobiles from "../images/Mask-Group-3@2x.png";
import {Fade} from "react-reveal";
import heroData from "../data/info.json";


const Information = () => {
	const BackG   = `url(${BgImgMobiles})`;
	const { items } = heroData;
	return (
		<div className="parallax-container  relative lg:px-[50px] bg-cover bg-no-repeat bg-[#244739] bg-top" style={{ backgroundImage: BackG }}>
			<div className="container px-[20px] lg:px-0 relative z-40 max-w-[1300px] w-[100%] lg:flex pt-[90px] lg:pt-[200px] lg:pb-[0px]  m-auto align-start">
				<div>
					<Fade bottom delay={'500'}>
					<h3 className="text-[32px] lg:text-[42px] lg:leading-[48px] text-[#7BFC76]">
						<span className={'font-bold'}>A</span>dditional <span className={'font-bold'}>I</span>nformation</h3>
					</Fade>
					<Fade bottom delay={'500'}  >
					<p className="text-[#ffffff] text-[16px] mt-[23px] mb-[50px] max-w-[475px]">
						Discover up-to-date assets that explore current topics, solutions
						and challenges facing enterprises in the world for generative AI.
					</p>
					</Fade>
				</div>
			</div>
			<div className="container px-[20px] lg:px-0  relative z-40 max-w-[1300px] w-[100%] lg:flex pb-[80px] lg:pb-[200px]  m-auto align-start">
				<Fade bottom delay={'500'}  >
				<div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 lg:gap-[40px] gap-[40px] md:gap-[10px] w-[100%]">

					{items.map((brief, index) => (
						<div key={index} className="col-span-1 bg-[#E4FFD6] drop-shadow-md">
							<div className="p-[15px] lg:p-[34px] flex flex-wrap h-full">
								<div>
									<p className="w-fulltext-[#6E6E6E] text-[9px] lg:text-[15px] mb-[10px]">
										SOLUTION BRIEF
									</p>
									<p className="text-[#247554] text-[30px] md:text-[24px] lg:text-[30px] leading-[36px] md:leading-[30px] lg:leading-[36px] text-left w-full mb-[15px]">
										{brief.title}
									</p>
									<p className="w-full text-[#000000] text-[16px] leading-[24px] text-left">
										{brief.description}
									</p>
								</div>

								<p className="w-full mt-[30px] lg:mt-[82px] justify-end">
									<a
										href={brief.downloadLink}
										target="_blank"
										className="inline-block px-[10px] py-[10px] bg-[#4DE760] text-[16px] text-[#000000] font-bold">
										Download
									</a>
								</p>
							</div>
						</div>
					))}
				</div>
				</Fade>
			</div>
		</div>
	);
};

export default Information;