import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import dellLogo from '../images/DT_PartnerProgram_Black@2x.png';
import nVidia from '../images/nvidia@2x.png';
import presidio from '../images/logo_cwb.png';
import dellLogoWhite from "../images/white-dell@2x.png";
import nVidiaWhite from "../images/white-nvidia@2x.png";
import presidioWhite from "../images/logo_cwb.png";
import ChevronDownIcon from './ChevronDownIcon';

const Navigation = () => {
	const [navbarBackground, setNavbarBackground] = useState('bg-transparent');
	const [showLogos, setShowLogos] = useState(false);
	const [showResourcesMenu, setShowResourcesMenu] = useState(false);
	const [showMobileMenu, setShowMobileMenu] = useState(false);
	const location = useLocation();

	const isResourcesPink =
		location.pathname === '/webinars' ||
		location.pathname === '/insights' ||
		location.pathname === '/information' ||
		location.pathname.startsWith('/guide-');

	const isMobileLinkPink = location.pathname === '/webinars';

	const toggleResourcesMenu = () => {
		setShowResourcesMenu(!showResourcesMenu);
	};

	const toggleMobileMenu = () => {
		setShowMobileMenu(!showMobileMenu);
	};

	useEffect(() => {
		const handleScroll = () => {
			const logosElement = document.getElementById('logos');
			if (logosElement) {
				const logosElementTop = logosElement.getBoundingClientRect().top;
				setNavbarBackground(logosElementTop <= 0 ? 'bg-white' : 'bg-transparent');
				setShowLogos(logosElementTop <= 0);
			}
		};

		// Initial call to set navbarBackground
		handleScroll();

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const linkClassName = () => {
		if (location.pathname === '/') return 'font-semibold text-gray-700';
		if (location.pathname === '/guide') return 'font-semibold text-gray-700';
		if (location.pathname === '/contact') return 'font-semibold text-white';
		if (location.pathname === '/webinars') return 'font-semibold text-gray-700 text-[#349E5F]';
		if ((location.pathname === '/contact') && navbarBackground === 'bg-white !bg-[#031859]') return 'font-semibold !text-gray-700';
		if ((location.pathname === '/insights' || location.pathname === '/contact' || location.pathname === '/information' || location.pathname.startsWith('/guide-')) && navbarBackground === 'bg-white') return 'font-semibold text-gray-700';
		return 'font-semibold text-white';
	};

	return (
		<nav className={`navbarNav fixed top-0 left-0 z-50 w-full py-[10px] lg:py-[38px] transition duration-300 ${navbarBackground} ${location.pathname === '/contact' ? '!bg-[#031859]' : ''}`} >
		<div className="container max-w-[1200px] px-[20px] lg:px-0 mx-auto flex justify-between relative">
				<div className="logoWrapper w-[50%] relative z-50">
					{(location.pathname === '/contact') ? (
						<div className={`flex items-center justify-start gap-[20px] lg:gap-[60px]`}>
							<img src={dellLogoWhite} className="w-[65px] lg:w-[160px] h-auto" alt="Dell Logo" />
							<img src={nVidiaWhite} className="w-[65px] lg:w-[130px] h-auto" alt="Nvidia Logo" />
							<img src={presidioWhite} className="w-[65px] lg:w-[50px] h-auto" alt="Client Logo" />
						</div>
					) : ((location.pathname === '/insights' || location.pathname === '/information' || location.pathname === '/guide-1' || location.pathname === '/guide-2' || location.pathname === '/guide-3') && navbarBackground === 'bg-transparent') ? (
						<div className={`flex items-center justify-start gap-[20px] lg:gap-[60px]`}>
							<img src={dellLogoWhite} className="w-[65px] lg:w-[160px] h-auto" alt="Dell Logo" />
							<img src={nVidiaWhite} className="w-[65px] lg:w-[130px] h-auto" alt="Nvidia Logo" />
							<img src={presidioWhite} className="w-[65px] lg:w-[50px] h-auto" alt="Client Logo" />
						</div>
					) : ((location.pathname === '/' || location.pathname === '/webinars' || location.pathname === '/guide')) ? (
						<div className={`flex items-center justify-start gap-[20px] lg:gap-[60px]`}>
							<img src={dellLogo} className="w-[65px] lg:w-[160px] h-auto" alt="Dell Logo" />
							<img src={nVidia} className="w-[65px] lg:w-[130px] h-auto" alt="Nvidia Logo" />
							<img src={presidio} className="w-[65px] lg:w-[50px] h-auto" alt="Client Logo" />
						</div>
					) : (navbarBackground === 'bg-white') ? (
						<div className={`flex items-center justify-start gap-[20px] lg:gap-[60px]`}>
							<img src={dellLogo} className="w-[65px] lg:w-[160px] h-auto" alt="Dell Logo" />
							<img src={nVidia} className="w-[65px] lg:w-[130px] h-auto" alt="Nvidia Logo" />
							<img src={presidio} className="w-[65px] lg:w-[50px] h-auto" alt="Client Logo" />
						</div>
					) : null}

				</div>
				<div className="w-[50%] max-w-[547px] flex justify-end">
					<div className="lg:hidden static z-50">
						<button className="block text-gray-700 hover:text-gray-900 focus:text-gray-900 focus:outline-none" onClick={toggleMobileMenu}>
							{showMobileMenu ? (
								<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 100 100">
									<line x1="10" y1="10" x2="90" y2="90" stroke="#349E5F" stroke-width="4" />
									<line x1="10" y1="90" x2="90" y2="10" stroke="#349E5F" stroke-width="4" />
								</svg>
							) : (
								<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 100 100">
									<line x1="10" y1="30" x2="80" y2="30" stroke="#349E5F" stroke-width="4" />
									<line x1="10" y1="50" x2="80" y2="50" stroke="#349E5F" stroke-width="4" />
									<line x1="10" y1="70" x2="80" y2="70" stroke="#349E5F" stroke-width="4" />
								</svg>
							)}
						</button>
					</div>
					<div className={`lg:hidden ${showMobileMenu ? 'block' : 'hidden'} absolute top-0 right-0 bg-white w-[100vw] max-w-[200px] h-[100vh] z-40`}>
						<ul className="text-center space-y-4 flex flex-col justify-start pt-[50px] pr-[20px] items-end w-full h-full top-0">
							<li>
								<NavLink exact to="/" className={`font-semibold !text-gray-700 t ${linkClassName()}`} activeClassName="text-[#349E5F]" onClick={toggleMobileMenu}>
									Overview
								</NavLink>
							</li>
							<li>
								<NavLink to="/guide" className={`font-semibold !text-gray-700 t ${linkClassName()}`} activeClassName="text-[#349E5F]" onClick={toggleMobileMenu}>
									Guide
								</NavLink>
							</li>
							<li>
								<div className={`relative ${showResourcesMenu ? 'text-[#349E5F]' : ''}`} onClick={toggleResourcesMenu}>
									<div className={`font-semibold relative mr-[20px] cursor-pointer ${isMobileLinkPink ? 'text-[#349E5F]' : (location.pathname === '/webinars' ? 'text-[#349E5F]' : 'text-gray-700')}`}>
										Resources
										<ChevronDownIcon />
									</div>
									{showResourcesMenu && (
										<div className="relative text-right">
											<ul className="py-2">
												<li className={'mb-[10px]'}>
													<NavLink to="/insights" className={`font-semibold !text-gray-700 ${linkClassName()}`} onClick={toggleMobileMenu}>
														Insights
													</NavLink>
												</li>
												<li className={'mb-[10px]'}>
													<NavLink to="/webinars" className={`font-semibold !text-gray-700 ${location.pathname === '/webinars' ? 'text-[#349E5F]' : 'text-gray-700'} py-1`} onClick={toggleMobileMenu}>
														Webinars
													</NavLink>
												</li>
												<li>
													<NavLink to="/information" className={`font-semibold !text-gray-700  ${linkClassName()}`} onClick={toggleMobileMenu}>

													Solution Briefs
													</NavLink>
												</li>
											</ul>
										</div>
									)}
								</div>
							</li>
							<li>
								<NavLink to="/contact" className={`font-semibold ${linkClassName()}`} activeClassName="text-[#349E5F]" onClick={toggleMobileMenu}>
									Contact us
								</NavLink>
							</li>
						</ul>
					</div>
					<ul className="hidden lg:flex justify-end gap-[20px] items-center space-x-4">
						<li>
							<NavLink exact to="/" className={linkClassName()} activeClassName="text-[#349E5F]">
								Overview
							</NavLink>
						</li>
						<li>
							<NavLink to="/guide" className={linkClassName()} activeClassName="text-[#349E5F]">
								Guide
							</NavLink>
						</li>
						<li>
							<div className={`relative group group-hover:opacity-0`}>
				                <span className={`font-semibold cursor-pointer ${isResourcesPink ? 'text-[#349E5F]' : linkClassName() + ' relative'}`}>
				                  Resources
				                  <ChevronDownIcon />
				                </span>
								<div className="absolute top-full left-0 mt-2 w-48 bg-white border rounded-lg shadow-lg opacity-0 group-hover:opacity-100 transition-opacity">
									<ul className="py-2 px-[20px]">
										<li>
											<NavLink to="/insights" className={`block text-gray-700 hover:text-[#349E5F] py-1`} onClick={toggleMobileMenu}>
												Insights
											</NavLink>
										</li>
										<li>
											<NavLink to="/webinars" className={`block ${location.pathname === '/webinars' ? 'text-[#349E5F]' : 'text-gray-700'} hover:text-[#349E5F] py-1`} onClick={toggleMobileMenu}>
												Webinars
											</NavLink>
										</li>
										<li>
											<NavLink to="/information" className={`block text-gray-700 hover:text-[#349E5F] py-1`} onClick={toggleMobileMenu}>
												Solution Briefs
											</NavLink>
										</li>
									</ul>
								</div>
							</div>
						</li>
						<li>
							<NavLink to="/contact" className={'bg-[#F5442D] px-5 py-2 contact !text-white'} activeClassName="!text-white" onClick={toggleMobileMenu}>
								Claim FREE AI Assessment
							</NavLink>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
};

export default Navigation;
