import React from 'react';
import { Fade } from 'react-reveal';
import heroData from '../data/info.json'; // Assuming the JSON file is named heroData.json

const Hero = () => {
	const { backgroundImage, items } = heroData;

	const BackG = `url(${backgroundImage})`;

	return (
		<div className="relative top-0">
			<div className="relative top-0 bg-[#244739] bg-contain bg-top bg-no-repeat" style={{ backgroundImage: BackG }}>
				<div className="container relative top-0 bottom-0 left-0 right-0 flex flex-wrap items-center z-40 max-w-[1200px] w-[100%] lg:flex pt-[90px] pb-[75px] lg:pt-[180px] lg:pb-[130px] m-auto align-middle">
					<div className="relative w-[100%] px-[20px] lg:px-0 pt-0 lg:p-[0] lg:pr-[30px]">
						<Fade left delay={'500'}>
							<div id="logos" className="hidden pt-[20px] lg:flex items-center justify-start mb-[40px] gap-[60px]"></div>
						</Fade>
						<div className="">
							<Fade bottom delay={'500'}>
								<div className="lg:text-[42px] text-[42px] font-light text-[#7BFC76] leading-[48px] mb-[20px]">
									<span className="font-bold">Additional</span> Information
								</div>
							</Fade>
							<Fade bottom delay={'500'}>
								<p className="text-[15px] mt-[30px] leading-[22px] lg:text-[16px] lg:leading-[24px] text-[#ffffff] mb-4 max-w-[360px]">
									Discover up-to-date assets that explore current topics, solutions, and challenges facing enterprises in the world for generative AI.
								</p>
							</Fade>
						</div>
					</div>
					<div className="container px-[20px] lg:px-0 relative z-40 max-w-[1300px] w-[100%] lg:flex lg:pb-[100px] pt-[70px] lg:pt-[130px] m-auto align-start">
						<Fade bottom delay={'500'}>
							<div className="grid grid-cols-1 md:grid-cols-3 gap-[40px] md:gap-[10px] lg:gap-[40px] w-[100%]">
								{items.map((brief, index) => (
								<div key={index}  className="col-span-1 bg-[#E4FFD6] drop-shadow-md">
									<div className="p-[15px] lg:p-[34px] flex flex-wrap">
										<p className="w-fulltext-[#6E6E6E] text-[9px] lg:text-[15px] mb-[10px]">
											SOLUTION BRIEF
										</p>
										<p className="text-[#247554] text-[30px] md:text-[24px] lg:text-[30px] leading-[36px] md:leading-[30px] lg:leading-[36px] text-left w-full mb-[15px]">
											{brief.title}
										</p>
										<p className="w-full text-[#000000] text-[16px] leading-[24px] text-left">
											{brief.description}
										</p>
										<p className="w-full mt-[30px] lg:mt-[101px] self-end">
											<a
												href={brief.downloadLink}
												target="_blank"
												className="inline-block px-[10px] py-[10px] bg-[#4DE760] text-[16px] text-[#000000] font-bold">
												Download
											</a>
										</p>
									</div>
								</div>
								))}
								{/*items.map((brief, index) => (
									<div key={index} className="col-span-1 bg-[#E4FFD6] drop-shadow-md">
										<div className="p-[15px] lg:p-[34px] flex flex-wrap">
											<p className="w-fulltext-[#6E6E6E] text-[9px] lg:text-[15px] mb-[10px]">
												SOLUTION BRIEF
											</p>
											<p className="text-[#247554] text-[30px] md:text-[24px] lg:text-[30px] leading-[36px] md:leading-[30px] lg:leading-[36px] text-left w-full mb-[15px]">
												{brief.title}
											</p>
											<p className="w-full text-[#000000] text-[16px] leading-[24px] text-left">
												{brief.description}
											</p>
											<p className="w-full mt-[30px] lg:mt-[101px] self-end">
												<a href={brief.downloadLink} target={'_blank'} className="inline-block px-[10px] py-[10px] bg-[#4DE760] text-[16px] text-[#000000] font-bold">
													Download
												</a>
											</p>
										</div>
									</div>
								))*/}
							</div>
						</Fade>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Hero;
