import React, {} from 'react';
import { Fade } from 'react-reveal';
import BgImgMobile from "../images/Overview@2x.png";
import BgImgDesktop from "../images/mobile-industries@2x.png";


const Overview = () => {
	const backgroundImage = window.innerWidth <= 1025 ? `url(${BgImgDesktop})` : `url(${BgImgMobile})`; // Set none for mobile
	return (
		<Fade bottom delay={'500'}  >
		<div className="parallax-container relative pt-[200px] lg:px-[50px] xl:px-[0] bg-cover bg-no-repeat" style={{ backgroundImage }}>
			<div className="container relative z-40 max-w-[1300px] w-[100%] lg:flex pt-[90px] lg:pt-[10px]  mx-auto align-middle">
				<div className="grid grid-cols-1 lg:grid-cols-5 gap-4 w-[100%] px-[20px] lg:px-0">
					<div className="lg:col-span-2 text-center lg:text-left">
						<Fade left  >
						<h3 className="text-[#7BFC76] text-[32px] lg:text-[42px] mb-[10px]">
							<span className={'font-bold'}>A</span>dvancing <span className={'font-bold'}>I</span>ndustries
						</h3>
						</Fade>
						<Fade left  >
						<p className="text-[#ffffff] text-[16px] leading-[24px] max-w-[380px] mx-auto md:mx-0">
							Generative AI is rapidly transforming industries, becoming a powerful tool with limitless application use cases and unlocking valuable opportunities.
						</p>
						</Fade>
					</div>
					<div className="col-span-1 text-center mt-[77px] lg:mt-0">
						<Fade top delay={'500'}  >
						<p className="text-[#7BFC76] text-[32px] lg:text-[36px] mb-[10px] font-bold">
							$118bn
						</p>
						</Fade>
						<Fade bottom delay={'500'}>
						<p className="text-[#ffffff] text-[16px] leading-[24px] max-w-[380px] mx-auto md:mx-none">
							is the global generative AI market size projected for 2032.<sup>1</sup>
						</p>
						</Fade>
					</div>
					<div className="col-span-1 text-center mt-[77px] lg:mt-0">
						<Fade top delay={'500'}  >
						<p className="text-[#7BFC76] text-[32px] lg:text-[36px] mb-[10px] font-bold">
							63%
						</p>
						</Fade>
						<Fade bottom delay={'500'}>
						<p className="text-[#ffffff] text-[16px] leading-[24px] max-w-[380px] mx-auto md:mx-none">
							of IT professionals believe that generative AI will have a significant impact on their business in the next three years.<sup>2</sup>
						</p>
						</Fade>
					</div>
					<div className="col-span-1 text-center mt-[77px] lg:mt-0">
						<Fade top delay={'500'}  >
						<p className="text-[#7BFC76] text-[32px] lg:text-[36px] mb-[10px] font-bold">
							100 terabytes
						</p>
						</Fade>
						<Fade bottom delay={'500'}>
						<p className="text-[#ffffff] text-[16px] leading-[24px] max-w-[380px] mx-auto md:mx-none">
							of data is used by the average generative AI project.<sup>2</sup>
						</p>
						</Fade>
					</div>
				</div>
			</div>
			<div className="px-[20px] lg:px-0 container relative z-40 max-w-[1300px] w-[100%] m-auto align-middle">
				<div className="grid grid-cols-1 pb-[20px] mt-[141px]">
					<div className="col-span-1 text-[10px] text-[#ffffff] mx-auto md:mx-0">
						<sup>1</sup> Presence Research (https://www.precedenceresearch.com/generative-ai-market)<br/>
						<sup>2</sup> “Generative AI: The Next Frontier for Business Innovation.” Gartner, 2022
					</div>
				</div>
			</div>
		</div>
		</Fade>
	);
};

export default Overview;