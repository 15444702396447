import React, {} from 'react';
import ThumbNail from "../images/101337_Dell-NVIDIA_Interactive_Thumbnail1.png";
import BgImgMobiles from "../images/All-in-Always-Inspiring-1@2x.png";
import clickToPlayImg from "../images/101337_Dell-NVIDIA_Campaign_Thumbnail1.png";
import {Fade} from "react-reveal";


const Inspiring = () => {
	const BgImage = `url(${ThumbNail})`; // Set none for mobile
	const backgroundImage = window.innerWidth <= 768 ? `none` : `url(${BgImgMobiles})`; // Set none for mobile
	return (
		<div className="parallax-container relative lg:px-[50px] pb-[80px] bg-cover bg-center bg-no-repeat bg-[#244739]" style={{ backgroundImage }}>
			<div className="container px-[20px] lg:px-0 relative z-40 max-w-[1200px] w-[100%] lg:flex pt-[90px] lg:pt-[160px] lg:pb-[160px]  m-auto align-start">
				<div className="lg:grid flex flex-col-reverse grid-cols-1 lg:grid-cols-4 gap-[60px] lg:gap-[150px] w-[100%]">

					<div className="col-span-2 text-left">
						<Fade bottom delay={'500'} >
						<h3 className="text-[#7BFC76] text-[32px] lg:text-[42px] mb-[10px] font-bold">
							A<span className="font-light">lways </span> I<span className="font-light">nspiring</span>
						</h3>
						</Fade>
						<Fade bottom delay={'500'}  >
						<p className="text-[#ffffff] text-[16px] leading-[24px] max-w-[414px] text-left">
							Tune in to our interactive webinar to hear more about the growing generative AI opportunity and how Dell Validated Design for Generative AI with NVIDIA places enterprises in prime position for business growth.
						</p>
						</Fade>
						<Fade bottom delay={'500'}  >
						<p className="mt-[30px]">
							<a href={'/#/webinars#webinar'} className="px-[10px] py-[10px] bg-[#4DE760] text-[#000000] font-bold">
								Watch the Webinar
							</a>
						</p>
						</Fade>
					</div>
					<div className="col-span-2">
						<Fade bottom delay={'500'}  >
							<a href={'/#/webinars#webinar'}>
							<figure className="bg-cover pb-[56%] w-[100%]" style={{ backgroundImage: BgImage }}>
							</figure>
							</a>
						</Fade>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Inspiring;