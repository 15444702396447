import React, {} from 'react';
import { Fade } from 'react-reveal';

import BgImgMobiles from "../images/Mask-Group-3@2x.png";
import ThumbNail from "../images/Group-1478@2x.png"
import Support from "../images/guide-support-image@2x.png";

const Hero = () => {
	const BackG   = `url(${BgImgMobiles})`;
	const ThumbNails = `url(${ThumbNail})`;
	return (
		<div className="relative top-0 pb-[100px]">
			<div className="relative top-0 bg-[#244739] bg-cover bg-left lg:bg-contain lg:bg-top bg-no-repeat" style={{ backgroundImage: BackG }}>
				<div className="container relative top-0 bottom-0 left-0 right-0 flex flex-wrap items-baseline z-40 max-w-[1200px] w-[100%] lg:flex pt-[90px] pb-[0px] lg:pt-[180px] lg:pb-[40px] m-auto content-start">
					<div className="relative w-[100%] lg:w-1/2 px-[20px] lg:px-0 pt-0 lg:p-[0] lg:pr-[30px]">
						<Fade left delay={'500'}  >
							<div id="logos" className="hidden pt-[20px] lg:flex items-center justify-start mb-[40px] gap-[60px]">

							</div>
						</Fade>
						<Fade bottom delay={'500'}  >
							<div className="">
								<div className="text-[15px] text-[#ffffff] mb-[10px] lg:mb-[5px]">GENERATIVE AI</div>
								<div className="lg:text-[42px] text-[32px] text-[#ffffff] max-w-[600px] leading-[48px] mb-[20px]">Adopt generative AI with easy and create the unforgettable with confidence</div>
							</div>
						</Fade>
					</div>
					<Fade bottom delay={'500'}  >
						<div className="relative w-[100%] lg:w-1/2  lg:px-0 pt-0 lg:p-[0] lg:pr-[30px]">
							<figure className="pb-[56%] lg:absolute top-0 w-full mx-auto right-0 lg:w-[100%] bg-cover lg:max-w-[100%] left-0" style={{ backgroundImage : ThumbNails }}></figure>
						</div>
					</Fade>
				</div>
			</div>
			<div className="max-w-[800px] px-[20px] lg:px-0 mx-auto lg:mt-[130px] mt-[40px]">
				<Fade bottom delay={'500'}  >
					<div className="font-semibold text-[#444444] mb-[10px]">
						CWB strongly believes that Generative AI has the potential to be the most transformative technology in improving the human experience, enhancing business operations and improving cyber security. It is rapidly transforming industries and everyday lives, opening up limitless application possibilities for CWB to create experiences that make long-lasting impacts on your customers, colleagues and community.
						<br/>
					</div>
				</Fade>
				<Fade bottom delay={'500'}  >
					<div className="text-[#444444] mb-[30px]">
						Although the global generative AI market is growing, using public generative AI models like ChatGPT in business settings raises several concerns for enterprises to consider before implementing models themselves. This is particularly important if organizations want to leverage models being used in the public domain that have not been developed and controlled specifically for enterprise use. <br/>
					</div>
				</Fade>
				<Fade bottom delay={'500'}  >
					<div className=" text-[#444444] mb-[30px]">
						<p className="text-[#349E5F] text-[18px] mb-[10px]">Content ownership</p>
						<p>Publicly available generative AI models can pose concerns around content ownership and intellectual property.
							Accuracy, truthfulness and source attribution become critical factors for businesses to consider before embracing
							these technologies. Relying on potentially outdated or incomplete data in public models makes it challenging to
							verify facts and access real-time information.</p>
					</div>
				</Fade>
				<Fade bottom delay={'500'}  >
					<div className=" text-[#444444] mb-[30px]">
						<p className="text-[#349E5F] text-[18px] mb-[10px]">Data quality</p>
						<p>It is vital that all machine learning models, especially generative AI, has access to high-quality training data due to the vast amounts of data required for accurate results. Enterprises must ensure their data is clean, well-labeled and representative of the objectives they aim to achieve. </p>
					</div>
				</Fade>
				<Fade bottom delay={'500'}  >
					<div className=" text-[#444444] mb-[30px]">
						<p className="text-[#349E5F] text-[18px] mb-[10px]">Model complexity</p>
						<p>Building and deploying generative AI models can be resource-intensive and time-consuming. For businesses lacking powerful hardware or dealing with large datasets, this complexity presents a significant challenge. However, careful planning can help overcome these obstacles and unlock the benefits of generative AI.</p>
					</div>
				</Fade>
				<Fade bottom delay={'500'}  >
					<div className=" text-[#444444] mb-[30px]">
						<p className="text-[#349E5F] text-[18px] mb-[10px]">Ethical considerations</p>
						<p>Using generative AI models to create content or make decisions with real-life consequences raises ethical implications and impacts the authenticity of business outcomes and customer experiences. Businesses must diligently assess potential ethical concerns and ensure that their generative AI models do not cause harm.</p>
					</div>
				</Fade>
				<Fade bottom delay={'500'}  >
					<div className=" text-[#444444] mb-[30px]">
						<p className="text-[#349E5F] text-[18px] mb-[10px]">Sustainability</p>
						<p>The substantial computational resources and power demanded by large-scale generative AI models leads to increased energy consumption and environmental impact. Developing sustainable practices and technologies is crucial for mitigating these environmental effects and continuing to create the unforgettable.</p>
					</div>
				</Fade>
				<Fade bottom delay={'500'}  >
					<div className=" text-[#444444] mb-[30px]">
						<p className="text-[#349E5F] text-[18px] mb-[10px]">Regulatory compliance</p>
						<p>Depending on the industry and application, enterprises may need to adhere to specific regulatory requirements when implementing generative AI models. Ensuring compliance with regulations concerning data security, privacy and other aspects is essential for smooth integration.</p>
					</div>
				</Fade>
				<Fade bottom delay={'500'}  >
					<div className={'mb-[40px] mt-[40px]'}>
						<img src={Support} className={'h-auto w-full'}/>
					</div>
				</Fade>
				<Fade bottom delay={'500'}  >
					<div className=" text-[#444444]">
						<p className={'text-[#349E5F] text-[22px] mb-[10px]'}>Worth the challenge?</p>
						<p>Generative AI holds immense promise for enterprises like CWB to build the experience, secure what matters and connect like never before. However, addressing concerns related to content ownership, data quality, model complexity, ethics, sustainability and regulatory compliance is essential to unlocking the full potential of AI models and its full range of use cases.
							<br/><br/>
							Embracing AI-driven agility and innovation will enable enterprises to thrive in an ever-evolving digital landscape and create the unforgettable experiences that drive growth, competitiveness and customer satisfaction. As technology continues to advance, the ability to navigate the challenges of generative AI will become increasingly critical for enterprises seeking sustainable success and meaningful impact.</p>
					</div>
				</Fade>
			</div>
		</div>

	);
};

export default Hero;
